@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "./partials/pagination";
@use "./config/" as *;
@forward "./listing/news-listing";
.waf-photo-listing {
    .head-wrap {
        .title {
            @extend %mb-3;
        }
    }
    .first-list {
        padding: 0;
        & {
            display: unset;
            overflow-x: unset;
        }
        & > * {
            width: unset;
        }
    }
    .meta-category,
    .meta-author,
    .article-thumbnail-tagline {
        @extend %d-none;
    }
    .article {
        &-wrap {
            @extend %h-100;
        }
        &-thumbnail {
            @extend %h-100;
            @extend %w-100;
            @extend %relative;
            &::after {
                content: "";
                background: linear-gradient(0deg, var(--neutral-800) 0%, transparent 30%);
                pointer-events: none;
                z-index: var(--z-overlay);
                inset: 0;
                @extend %absolute;
            }
            .item-type-icon {
                top: 0;
                left: 0;
                height: 3.3rem;
                border-bottom-left-radius: 0;
            }
        }
        &-item {
            @extend %mb-4;
        }
        &-title {
            top: 5%;
            @extend %absolute;
            @extend %pure-white-900;
            @extend %font-14-pb;
        }
        &-meta {
            .meta-date {
                @extend %pure-white-900;
                @extend %font-10-pm;
            }
        }
    }
    .first-list,
    .second-list {
        .article-content {
            bottom: 0;
            left: 0;
            position: absolute;
            @extend %px-3;
            @extend %pb-3;
            .item-type-icon {
                @extend %d-none;
            }
        }
    }
    .pagination-wrap {
        @extend %flex;
    }
}
@include mq(col-md) {
    .waf-photo-listing {
        &.waf-listing {
            .first-list {
                margin-bottom: var(--space-5);
                & {
                    display: unset;
                    overflow-x: unset;
                }
                & > * {
                    width: unset;
                }
                & > div {
                    grid-area: 3 / 1 / 4 / 5;
                }
            }
            .article-list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 1.6rem;
            }
            .first-list {
                .article-item:nth-child(1) {
                    grid-area: 1 / 1 / 3 / 3;
                }
                .article-item:nth-child(2) {
                    grid-area: 1 / 3 / 2 / 4;
                }
                .article-item:nth-child(3) {
                    grid-area: 1 / 4 / 2 / 5;
                }
                .article-item:nth-child(4) {
                    grid-area: 2 / 3 / 3 / 4;
                }
                .article-item:nth-child(5) {
                    grid-area: 2 / 4 / 3 / 5;
                }
            }
            .second-list {
                .article-item:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 2;
                }
                .article-item:nth-child(2) {
                    grid-area: 1 / 2 / 2 / 3;
                }
                .article-item:nth-child(3) {
                    grid-area: 2 / 1 / 3 / 2;
                }
                .article-item:nth-child(4) {
                    grid-area: 2 / 2 / 3 / 3;
                }
                .article-item:nth-child(5) {
                    grid-area: 1 / 3 / 3 / 5;
                }
            }
            .article-item {
                margin-bottom: unset;
            }
        }
    }
}